export * from './nav-data';
export * from './seo-texts';

// -----
// from old ui-lib
// -----

// export const NOTES_PAGE_TEXT = {
//   title: "eka’s notes",
//   desc: "My digital garden of resources and thoughts. Loosely cultivated, contains bugs, always in progress.",
// };

// export const ALL_NOTES_SECTION_TITLE = "Latest Notes";

// export const INLINKS_SECTION_TITLE = "Links to this page";

// export const GARDEN_STATUSES = {
//   STAGE_ONE: {
//     name: "seed",
//     description:
//       "Quick rough draft of an idea/question. There’s a non-zero chance I will go back to this note and flesh it out.",
//   },
//   STAGE_TWO: {
//     name: "sprout",
//     description: "A public draft. Needs more tending and watering until it’s fully grown.",
//   },
//   STAGE_THREE: {
//     name: "evergreen",
//     description:
//       "A complete note. Significant change is unlikely, but I may do the occasional pruning and add relevant connections in the future.",
//   },
// };

// export const TEMPORARY_LANDING_TEXT = {
//   title: "Welcome to my personal site",
//   lineOne:
//     "Hello stranger! My name is Eka, a.k.a. “ekafyi” anywhere online (yes, my handle comes from this domain). I have not made anything beyond the Notes section but wanted to have something out.",
//   lineTwo:
//     "In the meantime, feel free to browse the (currently sparsely populated) notes and my coding posts on DEV, if that's of interest. Have a nice day!",
// };
