import { useEffect, lazy, Suspense } from "react"; // useContext
import { useInView } from "react-intersection-observer";
// import { Brand } from "..";
import { NAV_DATA } from "../../../public/shared-assets/texts";
import NavBrand from "./NavBrand";
import { outer as outerStyle } from "./NavContent.css";
import { NavListItem } from "./NavListItem";
import * as itemStyles from "./NavListItem.css";
// import { ClientLinkContext } from "../Layouts";
// import type { TClientLinkContext } from "../Layouts";

const ThemeSwitchButton = lazy(() => import("../Button/ThemeSwitchButton"));

/**
 * Used in both _standard_ and _modal_ views of navigation drawer (2xl and
 * up to lg breakpoints respectively).
 *
 * @link https://m3.material.io/components/navigation-drawer/guidelines
 */
const NavContent = ({ inDialog = false }: { inDialog?: boolean }) => {
  useEffect(() => {
    console.log("Nav mounts");
    return () => { console.log("Nav unmounts") }; // prettier-ignore
  }, []);

  // TODO (later,perf) lazy-load
  // ... lazy-load ClientLink on idle
  // const CustomLink = useContext(ClientLinkContext);
  // ... lazy-load theme switcher on visible (and/or idle?)
  const { ref, inView } = useInView({ triggerOnce: true });

  return (
    <nav ref={ref} className={outerStyle}>
      <NavBrand inDialog={inDialog} />
      <div data-nav-content-part="links">
        <hr className={itemStyles.separatorStrong} />
        <ul className={``}>
          {NAV_DATA.primary.items.map((item) => (
            // TODO (later) ListItem isActive and CustomLink props
            // use for checking: isActive={item.text === "Notes"}
            <NavListItem key={item.text} {...item} />
          ))}
        </ul>
        <hr className={itemStyles.separator} />
        <ul className={``}>
          {NAV_DATA.social.items.map((item) => (
            <NavListItem key={item.text} {...item} isSocial />
          ))}
        </ul>
      </div>
      <div className={`${itemStyles.outerHideableText} lg:my-2 2xl:my-0`}>
        <Suspense fallback={null}>
          {inView ? (
            <ThemeSwitchButton className={itemStyles.link}>
              <div className={itemStyles.iconContainer}>
                <svg width={20} height={20}>
                  <use xlinkHref={`/shared-assets/images/icons.svg#sun`} />
                </svg>
              </div>
              <span className={itemStyles.textHideable}>Switch theme</span>
            </ThemeSwitchButton>
          ) : null}
        </Suspense>
      </div>
    </nav>
  );
};

export default NavContent;
