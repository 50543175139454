import { ClientLinkProvider, SiteLayout } from "@ekafyi/viteui-lib/components"; //
import { veLightTheme, veDarkTheme } from "@ekafyi/viteui-lib/styling-configs/ve-global.css";
import Link from "next/link";
// import { useRouter } from "next/router";
import Script from "next/script";
import type { ReactNode } from "react";
import { Fragment } from "react";
import { DefaultSeo, defaultSeoConfig } from "@/lib/next-seo";

const AppProviders = (props: { children?: ReactNode }) => {
  // const router = useRouter(); // router.route, eg. "/notes/[slug]"
  return (
    <>
      {/* 
        Example umami custom event usage:
        Add CSS class `.umami--click--copy-code` in copy code button 
      */}
      {process.env.NEXT_PUBLIC_UMAMI && (
        <Script
          src={process.env.NEXT_PUBLIC_UMAMI.split(",")[0]}
          data-website-id={process.env.NEXT_PUBLIC_UMAMI.split(",")[1]}
          data-domains={`eka.fyi`}
          strategy="lazyOnload"
        />
      )}
      <DefaultSeo {...defaultSeoConfig} />
      <Fragment key={`${veLightTheme} ${veDarkTheme}`}>
        <ClientLinkProvider linkComponent={Link}>
          <SiteLayout>{props.children}</SiteLayout>
        </ClientLinkProvider>
      </Fragment>
    </>
  );
};

export default AppProviders;
