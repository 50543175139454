import * as styles from "./NavBrand.css";

const NavBrand = ({ inDialog = false }: { inDialog?: boolean }) => {
  const imageNode = (
    <img
      src="/shared-assets/branding/icon-180x180.png"
      alt=""
      width={24}
      height={24}
      loading="lazy"
    />
  );

  return inDialog ? (
    <a className={styles.outerInDialog} href="/" aria-label="Home">
      {imageNode}
      <span>eka.fyi</span>
    </a>
  ) : (
    <div className={styles.outer}>
      <a className={styles.link} href="/" aria-label="Home">
        <div className={styles.iconContainer}>{imageNode}</div>
        <span className={styles.text}>eka.fyi</span>
      </a>
    </div>
  );
};

export default NavBrand;
