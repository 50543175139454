// import { createContext } from "react";
import React, { createContext } from "react";

type MinimalInternalLinkprops = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  href: any;
  prefetch?: boolean;
  scroll?: boolean;
};

type TLink = React.ForwardRefExoticComponent<
  Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof MinimalInternalLinkprops> &
    MinimalInternalLinkprops & {
      children?: React.ReactNode;
    } & React.RefAttributes<HTMLAnchorElement>
>;

export type TClientLinkContext = TLink | null;

/**
 * Context to enable custom client-side Link components, eg. next/link.
 *
 * Usage:
 * ```
 * const CustomLink = useContext(ClientLinkContext)
 * ```
 *
 * @link https://github.com/vercel/next.js/discussions/15560#discussioncomment-2509277
 */
export const ClientLinkContext = createContext<TClientLinkContext>(null);

/**
 * Context Provider to enable custom client-side Link components, eg. next/link.
 *
 * Usage:
 * ```
 * <CustomLink href={`/`}>
 *   <a href={`/`}>home</a>
 * </CustomLink>
 * ```
 *
 * @link https://github.com/vercel/next.js/discussions/15560#discussioncomment-2509277
 */
export const ClientLinkProvider = ({
  linkComponent = null,
  children,
}: {
  linkComponent: TClientLinkContext;
  children: React.ReactNode;
}) => {
  return <ClientLinkContext.Provider value={linkComponent}>{children}</ClientLinkContext.Provider>;
};
