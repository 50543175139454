export const SITE_META_TEXT = {
  shortTitle: "Eka’s site",
  title: "Eka’s personal site | @ekafyi",
  description:
    "Software developer (frontend web), maker at heart, not a potato. Making sense of things and writing about it. Mostly harmless.",
  imageTexts: { title: "Eka’s personal site", description: "💻☕️🎵📝" },
};

export const NOTES_META_TEXT = {
  title: "Eka’s Notes",
  description:
    "Personal resources & thoughts. Web development, digressions, world domination plans.",
  imageTexts: {
    title: "Eka’s Notes",
    description: "My digital garden of resources and thoughts",
  },
};
