import type { ReactNode } from "react";
import { NavContent } from "../Nav";
import * as styles from "./SiteLayout.css";

// !!(ideas)
// - add noSidebar prop for full screen pages (if necessary)
// - add current route prop (passed from next app) to style nav active item
export const SiteLayout = ({ children = null }: { children?: ReactNode }) => {
  return (
    <div className={styles.outer}>
      <div data-site-layout-part="side" className={`hidden lg:block`}>
        <NavContent />
      </div>
      {children}
    </div>
  );
};
