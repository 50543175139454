// import { useEffect, lazy, Suspense } from "react"; // useContext, useEffect
// import { useInView } from "react-intersection-observer";
// import { NAV_DATA } from "../../../public/shared-assets/texts";
// import { ClientLinkContext } from "../Layouts";
import type { TClientLinkContext } from "../Layouts";
import * as styles from "./NavListItem.css";

type NavListItemProps = {
  text: string;
  href: string;
  iconKey?: string;
  isActive?: boolean;
  isSocial?: boolean;
  CustomLink?: TClientLinkContext;
};

export const NavListItem = ({
  text,
  href,
  iconKey,
  isActive = false,
  isSocial = false,
  CustomLink = null,
}: NavListItemProps) => {
  const linkNode = (
    <a
      href={href}
      className={styles.link}
      rel={href.startsWith("https://") ? "external" : undefined}
      aria-label={text}
      aria-current={isActive ? "page" : undefined}
    >
      {iconKey && (
        <div className={styles.iconContainer}>
          <svg width={20} height={20}>
            <use xlinkHref={`/shared-assets/images/icons.svg#${iconKey}`} />
          </svg>
        </div>
      )}
      <span className={isSocial ? styles.textHideable : styles.text}>{text}</span>
    </a>
  );

  return (
    <li className={isSocial ? styles.outerHideableText : styles.outer}>
      {CustomLink && !href.startsWith("https://") ? (
        <CustomLink href={href}>{linkNode}</CustomLink>
      ) : (
        linkNode
      )}
    </li>
  );
};
