import { SITE_META_TEXT } from "../../public/shared-assets/texts";

export { DefaultSeo } from "next-seo";

/**
 * Site-level default SEO.
 * Not exported as component from here to keep src/lib consistent, no .tsx component files.
 *
 * @example
 * import { DefaultSeo, defaultSeoConfig } from "@/lib/next-seo";
 * return <DefaultSeo {...defaultSeoConfig} />
 */
export const defaultSeoConfig = {
  defaultTitle: SITE_META_TEXT.title,
  openGraph: {
    type: "website",
    locale: "en",
    site_name: "eka.fyi",
  },
  twitter: {
    handle: "@ekafyi",
    cardType: "summary_large_image",
  },
  additionalMetaTags: [
    {
      name: "theme-color",
      content: "#1f233d",
    },
  ],
  additionalLinkTags: [
    {
      rel: "icon",
      href: "/favicon.ico",
    },
    {
      rel: "apple-touch-icon",
      href: "/shared-assets/branding/icon-180x180.png",
      sizes: "180x180",
    },
    {
      rel: "manifest",
      href: "/manifest.json",
    },
  ],
};
