export const NAV_DATA = {
  primary: {
    items: [
      // { text: "About", href: "/", iconKey: "grain" },
      { text: "Notes", href: "/notes", iconKey: "triangle" },
      { text: "Projects", href: "/projects", iconKey: "circle" },
      { text: "Speaking", href: "/speaking", iconKey: "square-rotated" },
    ],
  },
  social: {
    items: [
      {
        text: "GitHub",
        href: "https://github.com/ekafyi",
        iconKey: "brand-github",
      },
      { text: "DEV", href: "https://dev.to/ekafyi", iconKey: "brand-devto" },
      { text: "Contact", href: "mailto:eka@eka.fyi", iconKey: "mail" },
    ],
    footerItems: [
      {
        text: "GitHub",
        href: "https://github.com/ekafyi",
      },
      { text: "Linkedin", href: "https://linkedin.com/in/ekafyi" },
      { text: "DEV", href: "https://dev.to/ekafyi", iconKey: "brand-devto" },
      { text: "Twitter", href: "https://twitter.com/ekafyi" },
      { text: "Ko-fi", href: "https://ko-fi.com/ekafyi", footerOnly: true },
    ],
  },
};
