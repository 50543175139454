import type { NextPage } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import type { ReactElement, ReactNode } from "react";
import { AppProviders } from "@/components/providers";

/**
 * Import global styles, global css or polyfills.
 * i.e.: import '@/assets/theme/style.scss'
 *
 * Use @font-face in CSS file to load custom fonts.
 * Don't use fontsource due to the bug with Inter & MacOS.
 * @link https://github.com/google/fonts/issues/2602
 * @link https://github.com/fontsource/fontsource/issues/243
 */
import "@ekafyi/viteui-lib/styles/global.css";

/**
 * @link https://nextjs.org/docs/basic-features/layouts#per-page-layouts
 */
export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

export type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
  // Workaround for https://github.com/zeit/next.js/issues/8592
  err?: Error;
};

/**
 * @link https://nextjs.org/docs/advanced-features/custom-app
 */
const MyApp = ({ Component, pageProps, err }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <AppProviders>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {/* Add key to Component if using framer-motion provider for page transitions */}
      {getLayout(<Component {...pageProps} err={err} />)}
    </AppProviders>
  );
};

export default MyApp;
